/** @format */

/*
  Only update this styles if it's not possible to theme datepicker in _variables.scss

  Checkout react-datepicker datepicker.scss for reference:
  https://github.com/Hacker0x01/react-datepicker/blob/master/src/stylesheets/datepicker.scss
*/
@import "node_modules/react-datepicker/src/stylesheets/datepicker.scss";


.react-datepicker-wrapper {
  width: 100%;
}

.popover-datepicker {
  max-width: 20rem;
}

.react-datepicker {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  &__header {
    padding: 0;
  }

  &__month-container {
    width: 100%;
    float: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__month {
    display: flex;
    flex-wrap: wrap;
    max-width: 280px;
  
    &-wrapper {
      display: contents;
    }
  }

  &__year {

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 280px;
    }
  
    .react-datepicker__year-text {
      display: inline-block;
      width: 3.75rem;
      line-height: 3.75rem;
      margin: 10px 5px;
    }
  }

  &__month {
    margin: $datepicker__margin;
    text-align: center;
  
    .react-datepicker__month-text,
    .react-datepicker__quarter-text {
      display: inline-block;
      width: 3.75rem;
      line-height: 3.75rem;
      margin: 10px 5px;
      border-radius: 100%;
    }
  }

  &__day-names {
    white-space: nowrap;
    margin-bottom: 0px;
  }
}



.react-datepicker-popper {
  z-index: 4;
  box-shadow: $box-shadow-sm;
  background-color: $white;
}



.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker-time__header {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 6px;
}


.react-datepicker__time-container {
  float: none;
  width: 90px;
  @include media-breakpoint-up(sm) {
    border-left: 1px solid $light-gray-300;
  }

  &--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -87px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;

    

    .react-datepicker__time-box {
      width: 90px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;

      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box;

        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }

          &--selected {
            background-color: $datepicker__selected-color;
            color: white;
            font-weight: bold;

            &:hover {
              background-color: $datepicker__selected-color;
            }
          }

          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: not-allowed;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}



.bp-datepicker {

  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {

    &:not([aria-disabled=true]) {
      border-radius: 100%;
    }
    
    &:hover {
      background-color: $datepicker__hover-bg-color;
    }

  
    
    &--today {
      font-weight: normal;
      background-color: $datepicker__today-bg-color;

      &:hover {
        background-color: $datepicker__today-hover-bg-color;
        color: $datepicker__today-hover-text-color;;
      }
    }

    &--keyboard-selected {
      background-color: $light-gray-50;
    }
  }
  .react-datepicker__month-text, 
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    &--disabled {
      cursor: not-allowed;

      &:hover {
        background-color: unset;
      }
    }

    &--selected {
      background-color: $datepicker__selected-bg-color;
      color: $datepicker__selected-text-color;
    
      &:hover {
        background-color: $datepicker__selected-bg-color;
        color: $datepicker__selected-text-color;
      }
    }
  }

  .react-datepicker__month-container {
    padding-bottom: 16px;
  }

  .react-datepicker__day {
    border-radius: 100%;

    > span {
      display: block;
      border-radius: 100%;
      width: 100%;
      height: 100%;
    }

    &:not([aria-disabled=true]):hover {
      background-color: unset;
    }

    &:hover {
      > span {
        background-color: $datepicker__hover-bg-color;
      }
    }

    &--outside-month {
      color: $datepicker__muted-color;
    }

    &--disabled {
      cursor: not-allowed;
      color: $light-gray-500;

      &:hover {
        > span {
          background-color: unset;
        }
      }
    }

  
    &--today {
      font-weight: normal;
      
      > span {
        background-color: $datepicker__today-bg-color;
      }
      
      &:hover {
        > span {
          background-color: $datepicker__today-hover-bg-color;
          color: $datepicker__today-hover-text-color;;
        }
      }
    }
  
    &--selected {
      > span {
        background-color: $datepicker__selected-bg-color;
        color: $datepicker__selected-text-color;
      }
      &:hover {
        > span {
          background-color: $datepicker__selected-bg-color;
          color: $datepicker__selected-text-color;
        }
      }
    }

  
    &--in-range, &--in-selecting-range {
      border-radius: 0;
      background-color: $datepicker__in-range-bg-color !important;
      > span {
        color: $dark-gray-700;
        background-color: unset;
      }
    }

    &--range-end, &--range-start, &--selecting-range-end, &--selecting-range-start {
      background-color: unset !important;
      position: relative;
      &:before {
        content: "";
        background-color: rgba($dark-gray-700, 0.075);
        position: absolute;
        top: 0;
        bottom:0;
      }
      &:hover {
        > span {
          background-color: $datepicker__selected-bg-color;
          color: $datepicker__selected-text-color;
        }
      }
      > span {
        background-color: $datepicker__selected-color;
        color: $white;
      }
    }
    &--range-end:not(&--range-start):before, &--selecting-range-end:not(&--selecting-range-start):before {
      left: 0;
      right: 50%;
    }
    &--range-start:not(&--range-end):before, &--selecting-range-start:not(&--selecting-range-end):before {
      left: 50%;
      right: 0;
    }
   
  }
}

