/** @format */

.notifications-list {
  max-height: 100%;
  overflow: auto;

  &__item.list-group-item {
    height: auto;
  }
  
}
