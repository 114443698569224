.dashboard-sidebar {
  position: fixed;
  left: 0;
  top: $topnav-height;
  bottom: 0;
  z-index: 10;
  display: none;
  .sidebar {
    display: none;
  }
  @include sidebar-visible {
    display: block;
    .sidebar {
      display: block;
    }
  }
}

.dashboard-sidebar--open {
  display: block;
  .sidebar {
    display: block;
  }
}

.dashboard-content {
  position: relative;
  z-index: 0;
  min-height: 100vh;
  min-height: calc(100vh - #{$footer-height-desktop});
  @include media-breakpoint-down(sm) {
    min-height: calc(100vh - #{$footer-height-mobile});
  }
  padding-top: $topnav-height;
  margin-left: 0;
  @include sidebar-visible {
    margin-left: $sidebar-collapsed-width;
  }
}

.dashboard-footer {
  flex-direction: column;
  height: $footer-height-mobile;

  @include sidebar-visible {
    margin-left: $sidebar-collapsed-width;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    height: $footer-height-desktop;
  }
}
