// TODO: check this
@import-normalize;

html,
body,
#root {
  font-family: $fontfamily;
  background-color: $light-gray-100;
  min-height: 100vh;
}

.react-grid-item {
  padding: 15px;
}

.sticky-outer-wrapper.active {
  .sticky-inner-wrapper {
    left: 0;
    right: 0;
    width: auto !important;
    @include sidebar-visible {
      left: $sidebar-collapsed-width;
    }
  }
}

.Toastify {
  &__toast-container {
    margin-top: $topnav-height;
  }
}

.react-grid-layout.overflow-hidden {
  overflow-x: hidden;
}
