/** @format */

.sidebar-wrapper {
  width: $sidebar-collapsed-width;
  height: 100%;  

  .sidebar {
    width: $sidebar-collapsed-width;
    height: 100%;
    position: relative;
    z-index: $zindex-modal;
    transition: width 0.2s ease;
    overflow-x: hidden;
    border-right: 1px solid $light-gray-300;

    &--open, &:hover {      
      width: $sidebar-width;
      box-shadow: $box-shadow-lg;
      
    }

    .nav-link__label {
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &--open,&:hover {
      .nav-link__label {
        opacity: 1;
      }
    }
  }

  .overlay {
    top: 0;
    width: 0;
    height: 100vh;
    position: absolute;
    z-index: $zindex-modal-backdrop;
    opacity: 0;
    transition: opacity 0.2s ease; 

    &--open {
      width: 100vw;
      background-color: $black;
      opacity: 0.5;     
      transition: opacity 0.2s ease; 
    }
  }
}
