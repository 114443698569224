/** @format */
.markets-widget {

  .accordion-item {
    &:first-child {
      .accordion-button {
        border-top-color: transparent;
      }
    }

    .accordion-button {
      display: flex;
      align-items: center;
      border-left: 1px solid ;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  
  }
   
  .market-event {
    &__duration {
      padding-right: 9px;
      display: flex;
      align-items: flex-end;

      & > span {
        position: relative;
        white-space: nowrap;
        display: inline-block;
        color: $white;
        text-align: center;
        background-color: $dark-gray-700;
        font-size: $font-size-xs;
        min-width: 26px;
        line-height: 12px;
        padding-top: 4px;
        padding-left: 4px;
        padding-right: 4px;
        height: 18px;

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          right: -9px;
          top: 0;
          border-top: 9px solid transparent;
          border-bottom: 9px solid transparent;
          border-left: 9px solid $dark-gray-700;
        }
      }
    }
  }


}
