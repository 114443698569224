// .flatpickr-calendar.static.arrowBottom {
//   bottom: calc(100% + 10px);
//   top: unset;
// }

// .flatpickr-calendar.hasTime.noCalendar.arrowBottom {
//   margin-top: -50px
// }


.your-data {
  @include media-breakpoint-up(md) {
    &__container {
      min-height: calc(
        100vh - #{$topnav-height} - #{$footer-height-desktop} - #{$breadcrumbs-height} 
      ); 
    }
  }

  &__container {
    .react-datepicker {
      @media only screen and (max-width: 350px) {
        margin-left: -26px;
      }
    }
  }

  &__filter_panel {
    height: 100%;
    width: 100%;
    @include media-breakpoint-down(md) {
      height: auto;
      width: 100%;
      .your-data__panel_content {
        min-height: 320px;
      }
    }
  }

  &__select_panel {
    height: 100%;
    @include media-breakpoint-down(lg) {
      height: auto;
      .your-data__panel_content {
        min-height: 320px;
      }
    }
  }

  &__buildings_list {
    flex: 1 1 auto;
    height: 300px;
  }

  &__building {
    &:hover {
      background-color: $light-gray-100;
      cursor: pointer;
    }  
  }
}

