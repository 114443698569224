/** @format */

.search-bar {
  position: relative;
  z-index: 1;

  &__filters {
    box-shadow: $box-shadow;
    background-color: $white;
    width: 100%;
    max-width: 24rem;
    position: absolute;
    z-index: $zindex-dropdown;
    right: 0;
    

    // TODO: hotfix for switch component, fix in bootstrap
    .custom-control.custom-switch {
      margin-right: -0.5rem;
      .custom-control-label {
        &::after,
        &::before {
          cursor: pointer;
        }
      }
    }
  }
}
