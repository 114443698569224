/** @format */

.recharts-text {
  font-weight: 400;
  font-size: 0.8125rem;
  color: $dark-gray-700
}

.recharts-default-tooltip {
  box-shadow: $box-shadow-sm;
}

.tooltip {
  &__stack-group {
    border-top: 1px solid $light-gray-300;
  }

  &__item-description {
    max-width: 200px;
  }

  &__item-additional-info {
    display: flex;
    flex-direction: column;
  }
}


.recharts-legend {
  &__item {
    text-align: right;
    &--toggle {
      cursor: pointer;
    }
    &--hidden {
      opacity: 0.5;
    }
    &--completly-hidden {
      display: none;
    }
  }
}

.label-wrapper {
  font-size: $font-size-xs;
  overflow: visible;
  position: fixed;

  &--direction-left {
    .cell-label {
      transform: translateX(-100%) translateY(-50%);
    }
  }
}

.cell-label {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: $font-size-xs;
  text-align: center;
  transform: translateY(-50%);
}

.recharts-legend-item-text {
  &__label {
    display: inline-block;
    width: 8em;
  }
}

.recharts-wrapper {
  --recharts-color-event-window-stroke: #{$light-gray-900};
  --recharts-color-event-window-fill: #{rgba(red($dark-gray-900), green($dark-gray-900), blue($dark-gray-900), 0.05)};
}
