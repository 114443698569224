@import "node_modules/leaflet/dist/leaflet"; // NOTE: Leaflet doesn't support scss, so it needs to be at the top, we can only overwrite css.

@import '~@bphxd/ds-core-react/lib/scss/bp-core';
@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

@import "base/mixins";
@import "base/variables";
@import "base/utilities";

// components
@import "components/card";
@import "components/buildings";
@import "components/datepicker";
@import "components/map";
@import "components/chart";
@import "components/notification";
@import "components/search";
@import "components/sidebar";
@import "components/market";
@import "components/navbar";
@import "components/map";
@import "components/dashboard";
@import "components/charts";
@import "components/scroll_top_button";


@import "layout/body";

@import "pages/yourdata";


// vendor
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;800&display=swap");



.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}

.sticky-outer-wrapper.active .sticky-inner-wrapper {
  z-index: $zindex-sticky;
}

.sticky-grid-item:not(.active) {
  height: 100%;

  .sticky-grid-item-inner {
    height: 100%;

    & > :first-child {
      height: 100%;
    }
  }
}

.Toastify {
  &__toast-container {
    margin-top: $topnav-height;
  }
}

// NOTE: Fix is included in ds-core-react@9.0.0 version. Remove after upgrade.
.btn:disabled svg {
  opacity: .5;
}

