.notifications-popover {
  min-width: 310px;
  width: calc(100vw - 10px);
  max-width: 400px;

  .popover-body {
    padding: 0;
    height: 490px;
    max-height: calc(100vh - 130px);
  }
}