/** @format */



.portfolio-card {
  .card-img-wrapper {
    width: 100%;
    padding-top: 56%;
    position: relative;


    img {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      top: 0;
    }
  }

  &--compact {
    flex-direction: row;

    &:hover {
      background-color: $light-gray-100;
    }

    .card-img-wrapper {
      padding-top: 0;
      width: 6rem;
      height: 100%;
      min-width: 6rem;
      min-height: 5rem;
    }
  }
}




.portfolio-detail-card {
  
  &__image {
    min-height: 5rem;
    min-width: 5rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

