/** @format */

.bg-overlay-blur {
  background-color: rgba(255, 255, 255, 0.85);
  filter: blur(0.5rem);
}

 .object-fit-contain {
  object-fit: contain !important;
 }

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}


.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.h-6 {
  height: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.h-8 {
  height: 2rem;
}
.w-10 {
  width: 2.5rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-16 {
  width: 4rem;
}
.w-18 {
  width: 4.5rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-28 {
  width: 7rem;
}
.w-32 {
  width: 8rem;
}
.w-40 {
  width: 10rem;
}
.w-48 {
  width: 12rem;
}
.w-52 {
  width: 13rem;
}
.w-56 {
  width: 14rem;
}
.w-64 {
  width: 16rem;
}

.align-self-start {
  align-self: self-start !important;
}

.min-w-0 {
  min-width: 0;
}

.min-w-sm {
  min-width: 24rem;
}

.scroll-gradient {
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(209, 209, 209, 0.5),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(209, 209, 209, 0.5),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 24px, 100% 24px, 100% 8px, 100% 8px;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.animate-pulse-text-info {
  animation: pulse-text-info 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse-text-info {
  0%,
  100% {
    color: $info
  }
  50% {
    color: $black;
  }
}

.animate-pulse-circle {
  background: $danger;
  border-radius: 50%;
  margin: 0.25rem;
  height: 0.5rem;
  width: 0.5rem;

  box-shadow: 0 0 0 0 lighten($danger, 20%);
  transform: scale(1);
  animation: pulse-circle 3s infinite;
}

@keyframes pulse-circle {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 lighten($danger, 20%);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.3rem lighten($danger, 20%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 lighten($danger, 20%);
  }
}
