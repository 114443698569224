$topnav-height: 72px;
$sidebar-collapsed-width: 72px;
$sidebar-width: 320px;

$breadcrumbs-height: 39px;

$footer-height-mobile: 100px;
$footer-height-desktop: 40px;

$fontfamily: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  Arial, sans-serif;

/***
    https://github.com/Hacker0x01/react-datepicker/blob/master/src/stylesheets/variables.scss
***/

$datepicker__background-color: $white;
$datepicker__border-color: $white;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__holidays-color: #ff6803 !default;
$datepicker__muted-color: rgba($dark-gray-700, .5);
$datepicker__selected-color: $dark-gray-700;
$datepicker__text-color: $dark-gray-700;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;
$datepicker__border-radius: 0;
$datepicker__day-margin: 0;
$datepicker__font-size: 0.875rem;
$datepicker__font-family: $fontfamily;
$datepicker__item-size: 2.5rem;
$datepicker__margin: 0;
$datepicker__navigation-button-size: 32px !default;
// Custom variables for bp theme
$datepicker__selected-bg-color: $dark-gray-700;
$datepicker__selected-text-color: $white;
$datepicker__hover-bg-color: $light-gray-300;
$datepicker__today-bg-color: rgba($dark-gray-700, 0.075);
$datepicker__today-hover-bg-color: $light-gray-600;
$datepicker__today-hover-text-color: $white;
$datepicker__in-range-bg-color: $light-gray-200;
$datepicker__in-range-hover-bg-color: $light-gray-300;