/** @format */

.buildings-list {
  max-height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.building-list-item {
  &__caret {
    opacity: 0.4;
  }

  &--link:hover {
    background: $light-gray-100;
    cursor: pointer;

    .building-list-item__caret {
      opacity: 0.9;
    }
  }
}


.buildings-grid-widget {

  $headerHeight: 73px;

  .search-bar {
    position: static !important;

    &__filters {
      top: $headerHeight;
      max-height: calc(100% - #{$headerHeight});
      overflow: auto;
    }
  }
}
